import { DonationFormType, ReceiverType } from './types'
import { Properties } from 'posthog-js'
import { ReceiverTypes } from '@betterplace/api-graphql-types'
import { toSnakeCase } from '@betterplace/utils'

function normalizeReceiverType(receiverType: ReceiverType | ReceiverTypes) {
  if (receiverType === ReceiverTypes.FundraisingEvent) {
    return 'FundraisingEvent'
  }
  if (receiverType === ReceiverTypes.Project) {
    return 'Project'
  }
  return receiverType
}

export function isWirWunder(receiverId: string | number) {
  let isWirWunder = false
  if (typeof document !== 'undefined') {
    const cookieStr = document.cookie.split('; ')
    isWirWunder =
      !!cookieStr.find((cookie) => cookie.startsWith(`ww-${receiverId}=`))?.split('=')[1] ||
      window.location.href.includes('/ww-')
  }
  return isWirWunder
}

export const extractIdFromSlug = (id: number | string) => {
  const numericId = typeof id !== 'number' ? parseInt(id, 10) : id
  if (isNaN(numericId)) return String(id)
  return String(numericId)
}

export const convertCentsToEuro = (amount: number) => {
  return amount / 100
}

export const normalizeData = (data: Properties) => {
  return Object.keys(data).reduce((acc: Properties, key: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    acc[key] = typeof data[key] === 'string' ? toSnakeCase(data[key]) : data[key]
    return acc
  }, {})
}

export function getDonationFormType({
  channel,
  receiverType,
  receiverId,
  showCodonation,
}: {
  receiverType: ReceiverType | ReceiverTypes
  receiverId: string | number
  showCodonation: boolean
  channel: string
}): DonationFormType {
  switch (channel) {
    case 'iframe':
      return DonationFormType.Iframe
    case 'platform':
      return normalizeReceiverType(receiverType)
    default: {
      if (isWirWunder(receiverId)) {
        if (showCodonation) {
          return DonationFormType.WirWunderWithCodonation
        }
        return DonationFormType.WirWunderWithoutCodonation
      }
      return DonationFormType.OtherClients
    }
  }
}

export default {
  extractIdFromSlug,
  convertCentsToEuro,
  normalizeData,
  getDonationFormType,
  isWirWunder,
}
